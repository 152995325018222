import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import appstore from '../../images/appstore.svg';

const AppStoreStyles = styled.div`
  text-align: center;
  margin: 40px;
`;

const CenteredDiv = styled.div`
  text-align: center;
`;

export default function PacingTimerPage({ data }) {
  return (
    <Layout>
      <SEO title="PacingTimer" />

      <h2>Pacing Timer</h2>

      <p>
        One thing I always found difficult in my classroom is pacing. Setting up
        a lesson, its fairly easy to figure out how much time to allocate to
        parts of a lesson, or an activity, or whatever. The hard part is keeping
        track of it for multiple lessons a day. I designed Pacing Timer to meet
        this need. Once you start the timer, you get a countdown for the current
        section, accompanied by a colored circle that you can check at a glance.
      </p>

      <p>
        The bar at the top shows the current section in a dark color, with a
        light color showing the current section relative to the entire activity.
      </p>

      <p>The bottom of the screen gives a prompt for the section coming up.</p>

      <p>
        A later update will offer alternate color schemes to choose from. Select
        your favorite in settings, the most popular choices will be added first.
      </p>

      <CenteredDiv>
        <Img fixed={data.appImage.childImageSharp.fixed} />
      </CenteredDiv>
      <hr />

      <p>
        The app starts with a screen that lets you define the sections of your
        activity. The +/- buttons change the time value of the section. The
        control on the left is for minutes, and the other is for seconds.{' '}
      </p>

      <p>
        The edit button lets you delete and reorder sections. The text field at
        the bottom lets you add new sections.
      </p>

      <p>
        When your presentation is ready, tap the play button to start the timer.
      </p>

      <CenteredDiv>
        <Img fixed={data.appImage2.childImageSharp.fixed} />
      </CenteredDiv>
      <hr />

      <p>
        Tapping anywhere on the screen pauses the presentation, and you can tap
        again to resume. You can also restart the activity from the beginning,
        or stop it early.
      </p>

      <p>
        If you have switched to another app, or your phone is asleep at the end
        of a section, you will get a notification telling you the section has
        ended.
      </p>

      <p>
        Pacing Timer respects your silent switch. If sounds are on, there is a
        "ding" to inform you of the end of the section. If your phone is set to
        silent, it will vibrate once to notify you.
      </p>

      <p>
        If you want to turn off sounds and vibration, there is a switch in
        settings to do so.
      </p>

      <CenteredDiv>
        <Img fixed={data.appImage3.childImageSharp.fixed} />
      </CenteredDiv>
      <AppStoreStyles>
        <a href="https://itunes.apple.com/us/app/pacing-timer/id913208024?ls=1&mt=8">
          <img alt="iOS App Store" src={appstore} />
        </a>
      </AppStoreStyles>
    </Layout>
  );
}

export const query = graphql`
  query {
    appImage: file(relativePath: { eq: "timer.png" }) {
      childImageSharp {
        fixed(width: 320) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    appImage2: file(relativePath: { eq: "timer2.png" }) {
      childImageSharp {
        fixed(width: 320) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    appImage3: file(relativePath: { eq: "timer3.png" }) {
      childImageSharp {
        fixed(width: 320) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
